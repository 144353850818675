<template>
    <input class="input font f-s-16 l-h-20 f-w-500"
        ref="input"
        v-model="text"
        :step="step"
        @input="handler"
        @focus="focus"
        @touchend="touchend"
        @blur="blur"
        @keyup.delete="$emit('backspace')"
        @keydown.enter.prevent="$emit('enter')"
        :id="id"
        :placeholder="placeholder"
        :readonly="readonly"
        :disabled="disabled"
        :name="name"
        :type="type"
        autocomplete="on"
        :maxlength="max"
        :autofocus="autofocus"
    />
</template>

<script>
export default {
  data() {
    return {
      text: '',
      readonly: this.isReadonly || false,
      setTimeout: false,
    };
  },
  props: {
    step: {
      type: [String, Number],
      default: 'any'
    },    
    autofocus:{
        type: Boolean,
        default: false
    },
    canEmitOnUpdate: {
      type: Boolean,
      default: true,
    },
    value: {},
    isReadonly: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'placeholder',
    },
    delay: {
      type: Number,
      default: 0,
    },
    sanitize: {
      type: Function,
      default: (v) => v,
    },
    max: {
      type: [String, Number],
      default: 120,
    },
  },
  mounted() {
    this.setValue();
  },
  watch: {
    value() {
      this.setValue();
    },
    text(text) {
      this.text = this.sanitize(text);
    },
  },
  methods: {
    setValue() {
      this.text = this.value;
    },
    touchend() {
      setTimeout(() => {
        this.$refs.input.focus();
        this.$refs.input.click();
      }, 100)
    },
    focus() {
      if (this.isReadonly) return;
      this.readonly = false;
      this.$emit('focus', true);
    },
    blur() {
      this.$emit('blur', false);
    },
    handler() {
      if (this.canEmitOnUpdate) {
        clearTimeout(this.setTimeout);

        this.setTimeout = setTimeout(() => {
          this.$emit('input', this.text);
        }, this.delay);
      }
    },
    clear() {
      this.text = '';
      this.handler();
    },
    onEnter(event) {
      event.preventDefault();
      this.$emit('enter')
    }
  },
};
</script>

<style lang="scss">
.input {
    border: unset;
    outline: unset;
    height: inherit;
    background-color: inherit;
    padding: 14px 12px;
    width: 100%;
}
</style>
