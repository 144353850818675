<template>
  <component :is="disabled ? 'span' : 'label'" class="radio-container" :class="{ inline }">
    <input type="radio" ref="radio" :name="name" :value="vData" class="radio-input" @input="handler" :checked="checked"
      :disabled="disabled" />
    <div class="radio-checkmark-container" :style="{
      minWidth: `${minWidth}px !important`,
      minHeight: `${minHeight}px !important`
    }">
      <span class="radio-checkmark"></span>
    </div>
    <div class="d-flex flex-column radio-label">
      <span v-if="label" :class="labelClass" class="font f-s-16 l-h-20 f-w-500">{{ label }}</span>
      <span v-if="caption" :class="captionClass" class="font f-s-12 l-h-16 f-w-500 l-t-s-3">{{ caption }}</span>
    </div>
  </component>
</template>

<script>
export default {
  props: {
    caption: {
      type: String,
      default: '',
    },
    labelClass: {
      type: String,
      default: '',
    },
    captionClass: {
      type: String,
      default: '',
    },
    minWidth: {
      type: [String, Number],
      default: 48,
    },
    minHeight: {
      type: [String, Number],
      default: 48,
    },
    value: {

    },
    vData: {
      type: [String, Boolean, Object, Number],
      default: '',
    },
    inline: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: 'input-name',
    },
    checked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handler() {
      if(this.disabled) return;

      if (this.$refs.radio.checked) {
        this.$emit('input', this.vData);
      } else {
        this.$emit('input', false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.radio {
  &-container {
    &.inline {
      display: inline-flex !important;

      .radio-checkmark-container {
        margin: 0px !important;
      }
    }

    margin: 0px;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    // padding: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:hover .radio-input:not(:checked)~.radio-checkmark-container .radio-checkmark {
      // background-color: #ccc;
    }
  }

  &-input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;

    &:checked~.radio-checkmark-container .radio-checkmark {
      background-color: #141414;
      border: 2px solid #141414;
    }

    &:checked {
      &:disabled~.radio-checkmark-container .radio-checkmark {
        background-color: #AFAFAF;
        border: 2px solid #AFAFAF;
      }
    }

    &:disabled~.radio-checkmark-container .radio-checkmark {
      background-color: #F6F6F6;
      border: 2px solid #F6F6F6;
    }

    &:checked~.radio-checkmark-container .radio-checkmark::after {
      display: block;
    }
  }

  &-checkmark-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
  }

  &-checkmark {
    position: relative;
    height: 20px;
    width: 20px;
    min-width: 20px;
    min-height: 20px;
    background-color: #EEEEEE;
    border-radius: 50%;
    border: 2px solid #545454;

    &::after {
      content: "";
      position: absolute;
      display: none;
      width: 6px;
      height: 6px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      background: white;
    }
  }

  &-label {
    margin: 0px;
    padding: 14px 0px;
  }
}
</style>
