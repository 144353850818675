<template>
  <input-container  :id="inputId"
   :inputFieldId="`input-field-id-${name}`"
    :label="label" :assistive="assistive"
    :tooltip-text="tooltipText" 
    :no-assistive="noAssistive" 
    :max="max" :body-id="bodyId" 
    :count="input ? input.length : 0"
    :char-counter="charCounter" 
    :classes="classes" 
    :class="{ focused, disabled, invalid, readonly }"
    :bodyClass="bodyClass"
    :tooltip-custom-style="tooltipCustomStyle"
    >
    
    <template #container>
      <slot name="icon-left"></slot>
      <InputComponent 
        ref="input" 
        :type="inputType"
        :step="step"
        :class="inputClass"
        :placeholder="placeholder" 
        :id="`input-field-id-${name}`" 
        :name="name" 
        v-model="input"
        @focus="focus" 
        @blur="blur" 
        @backspace="$emit('backspace')"
        @enter="$emit('enter')"
        :disabled="disabled" 
        :delay="delay" 
        :max="max" 
        :sanitize="sanitize"
        :is-readonly="readonly" 
        :autofocus="autofocus"
        />
      <slot name="icon-right"></slot>
      <button type="button" class="btn fab fab-sm icon close color primary m-r-16" v-if="showCleanButton"
        @click="clear"></button>
        <slot name="enhancer-label" :enhancerLabel="enhancerLabel">
          <span class="font m-r-12 l-h-24 f-s-16 f-w-500" v-if="enhancerLabel">{{ enhancerLabel }}</span>
        </slot>

      <slot name="tooltip" v-if="tooltip">
        <b-tooltip :target="inputId" triggers="hover">
          {{ tooltip }}
        </b-tooltip>
      </slot>
    </template>
  </input-container>
</template>

<script>
import InputContainer from './input-container.vue'; 
import InputComponent from './input-component.vue';

export default {
  components: {
    InputContainer,
    InputComponent,
  },
  data() {
    return {
      focused: false,
      input: this.value || '',
    };
  },
  props: {
    step: {
      type: [String, Number],
      default: 'any'
    },
    inputClass: {
      type: String,
      default: '',
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    tooltipCustomStyle: {
      type: Object,
      default: () => ({})
    },
    tooltipText: {
      type: String,
      default: '',
    },
    inputId: {
      type: String,
      default: `input-container-id-${Math.random()}`
    },
    bodyClass: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    readonlyAction: {
      type: Boolean,
      default: true,
    },
    noAssistive: {
      type: Boolean,
      default: false,
    },
    enhancerLabel: {
      type: [Boolean, String],
      default: false,
    },
    search: {
      type: Boolean,
      default: false,
    },
    clean: {
      type: Boolean,
      default: false,
    },
    value: {},
    label: {
      type: [String, Boolean],
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    assistive: {
      type: [String, Boolean],
      default: false,
    },
    error: {
      type: [Boolean, String, Array],
      default: false,
    },
    classes: {
      type: String,
      default: 'p-b-32',
    },
    bodyId: {
      type: String,
      default: '',
    },
    sanitize: {
      type: Function,
      default: (v) => v,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    reduce: {
      type: Function,
      default: (v) => v,
    },
    delay: {
      type: Number,
      default: 0,
    },
    max: {
      type: [String, Number],
      default: 120,
    },
    charCounter: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: [Boolean, String],
      default: false,
    },
    inputType: {
      type: String,
      default: 'text'
    }
  },
  computed: {
    showCleanButton() {
      return !this.disabled && this.clean && this.value.length;
    },
    invalid() {
      return Boolean(this.error || this.error.length);
    },
  },
  watch: {
    input(input) {
      this.$emit('input', this.reduce(input));
    },
    value(value) {
      this.input = this.reduce(value);
    },
  },
  methods: {
    focus(focus) {
      this.focused = focus;
    },
    blur(blur) {
      this.focused = blur;
    },
    clear() {
      this.$refs.input.clear();
    },
  },
  mounted() {
    this.input = this.reduce(this.value);
  },
};
</script>
