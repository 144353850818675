<template>
  <component :is="tag" v-bind="$attrs" ref="button" type="button" class="font"
    :class="classes"
    @click="click"
    :style="style">
      <slot>
        <span class="btn-text d-flex" :class="textClass" v-if="!loading">
        <slot name="icon-left" ></slot>
        {{ text }}
        <slot name="icon-right" ></slot>
      </span>

      <svg v-if="loading" class="dots" width="30px" height="30px" viewBox="0 0 132 58" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns">
          <!-- Generator: Sketch 3.5.1 (25234) - http://www.bohemiancoding.com/sketch -->
          <title>dots</title>
          <desc>Created with Sketch.</desc>
          <defs></defs>
          <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">
              <g class="dots" sketch:type="MSArtboardGroup" :fill="loadingColor">
                  <circle class="dot1" sketch:type="MSShapeGroup" cx="25" cy="30" r="13"></circle>
                  <circle class="dot2" sketch:type="MSShapeGroup" cx="65" cy="30" r="13"></circle>
                  <circle class="dot3" sketch:type="MSShapeGroup" cx="105" cy="30" r="13"></circle>
              </g>
          </g>
      </svg>
      </slot>
      
    </component>
</template>

<script>
export default {
  name: 'my-button',

  props: {
    tag: {
      type: String,
      default: 'button'
    },
    loadingColor: {
      type: String,
      default: '#FFFFFF',
    },
    classes: {
      type: String,
      default: '',
    },
    label: {
      type: String,
    },
    text: {
      type: String,
    },
    primary: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'medium',
      validator(value) {
        return ['small', 'medium', 'large'].indexOf(value) !== -1;
      },
    },
    backgroundColor: {
      type: String,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    textClass: {
      type: String,
      default: '',      
    }
  },

  computed: {
    style() {
      return {
        backgroundColor: this.backgroundColor,
      };
    },
  },
  watch: {
    loading(loading) {
      try {
        if(this.$refs.button) {
          
          if(!this.$refs.button.style) return;

          if (loading) {
            this.$refs.button.style.width = `${this.$refs.button.offsetWidth}px`;
          } else {
            this.$refs.button.style.width = 'auto';
            this.$refs.button.blur()
          }        
        }
      } catch (error) {
        console.log(error)
      }
    },
  },
  methods: {
    click() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>
.dots {
  .dot1{
    animation: load 1s infinite;
  }

  .dot2{
    animation: load 1s infinite;
    animation-delay: 0.2s;
  }

  .dot3{
    animation: load 1s infinite;
    animation-delay: 0.4s;
  }
}

@keyframes load{
  0%{
    opacity: 0;
  }
  50%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
}
</style>
